// 防抖节流函数
/**
 * 节流防抖总结
 *   防抖：原理是维护一个定时器，将很多个相同的操作合并成一个。规定在延迟触发函数，如果在此之前触发函数，则取消之前的计时重新计时，只有最后一次操作能被触发。
 *   节流：原理是判断是否达到一定的时间来触发事件。某个时间段内只能触发一次函数。
 *   区别：防抖只会在最后一次事件后执行触发函数，节流不管事件多么的频繁，都会保证在规定时间段内触发事件函数。
 */

/**
 * 防抖函数 只有在某个时间内，没有再次触发某个函数时，才真正的调用这个函数
 * @param {*} fn 外部传入的函数
 * @param {*} delay 延迟的时间
 * @param {*} immediate 是否立即执行
 */
function debounce(fn, delay, immediate = false) {
  // 定义一个定时器， 保存上一次的定时器
  let timer = null;
  // 是否立即执行
  let isInvoke = false;

  // 真正执行的函数
  const _debounce = function (...args) {
    // 取消上一次的定时器
    if (timer) clearTimeout(timer);
    // 判断是否需要立即执行
    if (immediate && !isInvoke) {
      // 立即执行
      fn.apply(this, args);
      isInvoke = true;
    } else {
      // 延迟执行
      timer = setTimeout(() => {
        // 外部传入的真正要执行的函数 矫正this的指向
        fn.apply(this, args);
        isInvoke = false;
        timer = null;
      }, delay);
    }
  };
  // 封装取消功能
  _debounce.cancel = function () {
    if (timer) clearTimeout(timer);
    timer = null;
    isInvoke = false;
  };
  return _debounce;
}

/**
 * 节流函数 规定在一个单位时间内，只能触发一次函数。如果这个单位时间内触发多次函数，只有一次生效
 * @param {} fn 外部传入的函数
 * @param {*} interval 延迟的时间
 * @param {*} options
 * @returns
 */
function throttle(
  fn,
  interval = 2000,
  options = { leading: true, trailing: false }
) {
  const { leading, trailing } = options;
  // 记录上一次的开始的时间
  let lastTime = 0;
  let timer = null;
  // 时间触发时，真正执行的函数
  const _throttle = function () {
    // 获取当前事件触发时的时间
    const nowTime = new Date().getTime();
    // 当最后的时候和当前时间相等的时候(第一次没有触发)
    if (!lastTime && !leading) lastTime = nowTime;
    // 使用当前触发的时间和之前的时间间隔以及上一次开始的时间， 计算出还剩余多长事件需要去触发函数
    const remainTime = interval - (nowTime - lastTime);
    if (remainTime <= 0) {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      // 真正触发函数
      fn();
      // 保留上次触发的时间
      lastTime = nowTime;
      // 没有加定时器的时候直接return掉
      return;
    }
    // 只需要有一个定时器即可
    if (trailing && !timer) {
      timer = setTimeout(() => {
        timer = null;
        // 仅仅执行一次
        lastTime = !leading ? 0 : new Date().getTime();
        fn();
      }, remainTime);
    }
  };
  return _throttle;
}
export { debounce, throttle };
