// 用户管理
<template>
  <div class="user_manager">
    <!-- 视图部分 -->
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="user_manager_content">
          <div class="top_action margin_top_14">
            <!-- 新增账号 -->
            <div class="has_padding search_bt_active com_bt_action" @click.stop="addAccount">新增账号</div>

            <!-- 搜索关键字 -->
            <div class="school_search margin_left_30">
              <!-- <span class="margin_right_4">账号：</span> -->
              <el-input clearable v-model="accountValue" placeholder="请输入账号关键字"></el-input>
            </div>
          </div>

          <!-- 表格部分 -->
          <div class="dv_table_view_content">
            <el-table ref="table_u_m" style="width: 99%" border stripe :data="tableData" :max-height="maxHeight">
              <!-- 序号 -->
              <el-table-column type="index" label="序号" align="center" width="60">
                <template scope="scope">
                  <span>{{ (currPage - 1) * pageSize + scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <!-- 用户名 -->
              <el-table-column prop="name" align="center" label="用户名" width="140"></el-table-column>

              <!-- 权限类型 -->
              <el-table-column prop="role" align="center" label="权限类型" width="140"></el-table-column>

              <!-- 手机号 -->
              <el-table-column prop="tel" align="center" label="手机号" width="160"></el-table-column>

              <!-- 关联账户数 -->
              <el-table-column prop="num" align="center" label="关联账户数" width="120"></el-table-column>

              <!-- 最后登录时间 -->
              <el-table-column prop="login_time" align="center" label="最后登录时间" width="240"></el-table-column>

              <!-- 状态 -->
              <el-table-column align="center" label="状态" width="140">
                <template slot-scope="scope">
                  <div :class="[scope.row.state == 0 ? 'green_text' : 'tv_red']">
                    {{ scope.row.state == 0 ? "已启用" : "未启用" }}
                  </div>
                </template>
              </el-table-column>

              <!--  操作 -->
              <el-table-column align="center" label="操作" min-width="10%">
                <template slot-scope="scope">
                  <el-button class="bt_aciton" @click="doEditAction(scope.row)" type="text" size="small">编辑</el-button>

                  <!-- 超级管理员 只能修改自己的账号密码和其它管理员的账号 -->
                  <el-button class="bt_aciton" v-if="scope.row.isShowPwd" @click="doChangePwd(scope.row)" type="text" size="small">修改密码</el-button>
                  <el-button v-if="scope.row.role != '超级管理员'" class="bt_aciton" @click="doUseAction(scope.row)" type="text" size="small">{{
                    scope.row.state == 0 ? "禁用" : "启用"
                  }}</el-button>
                </template>
              </el-table-column>
            </el-table>

            <!-- 底部的分页器部分 -->
            <div class="bom_page margin_bom_20" v-if="tableData && tableData.length > 0">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalSize"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </template>
    </Layout>

    <!--  禁用和启用用户的弹框 -->
    <ChangeUserState @close="showDialog = false" v-if="showDialog" :showDialog="showDialog" :item="acitonItem" />
  </div>
</template>
<script>
import ChangeUserState from "@/components/dialog/ChangeUserState";
//导入防抖节流函数
import { debounce } from "@/utils/throttle.js";
export default {
  data() {
    return {
      //加载状态
      showLoading: 1,

      //禁用,启用用户的弹框控制值
      showDialog: false,

      //搜索关键字
      accountValue: "",

      //表格的最大高度
      maxHeight: 0,

      //总的数据条数
      totalSize: 0,

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //表格数据
      tableData: [],

      //操作的条目对象
      acitonItem: null,

      //获取当前登录的用户角色
      role: "",

      //获取当前登录的用户角色id
      roleId: "",
    };
  },
  created() {
    this.initData();

    //同步页面状态
    this.sysPageParam();

    //请求数据
    this.httpData();
  },
  methods: {
    //初始化数据
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 210;
      //获取当前登录的用户角色
      this.role = this.getUserRole();
      this.roleId = this.getUserInfo().id;
      console.log("当前账户登录的账户角色是:", this.role, this.roleId);
    },

    //请求数据
    httpData() {
      let param = this.getHttpParams();
      param.type = 1;
      param.page = this.currPage - 1;
      param.pagecount = this.pageSize;
      if (!this.isEmpty(this.accountValue)) {
        param.name = this.accountValue;
      }
      this.$http
        .fetchPost(this.$api.ADMINMANAGE, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let result = res.data;

          //将表格滚动到顶部
          this.$nextTick(() => {
            this.$refs.table_u_m.bodyWrapper.scrollTop = 0;
          });

          if (result.state == 200) {
            //获取总条数
            this.totalSize = result.data.totalNum;
            //获取表格数据
            this.tableData = result.data.infoList;

            //设置是否显示修改密码的状态
            this.tableData.forEach((item) => {
              this.$set(item, "isShowPwd", this.isShowUpdataPwd(item));
            });

            console.log("创建好的表格数据是:", this.tableData);
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //新增账号
    addAccount() {
      this.savePageParam();
      this.$router.push({ path: "/Home/AddAccount", query: {} });
    },

    //编辑
    doEditAction(item) {
      this.savePageParam();
      this.$router.push({
        path: "/Home/EditSysAccount",
        query: { id: item.id },
      });
    },

    //修改密码
    doChangePwd(item) {
      this.savePageParam();
      this.$router.push({
        path: "/Home/UpdatePwd",
        query: { name: item.name, tel: item.tel, id: item.id },
      });
    },

    //禁用,启用
    doUseAction(item) {
      this.acitonItem = item;
      this.showDialog = true;
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      //重新刷新数据
      this.httpData();
    },

    //页面改变时触发
    handleCurrentChange(val) {
      this.currPage = val;
      //重新刷新数据
      this.httpData();
    },

    //保存页面跳转的key值
    savePageParam() {
      //当前页面
      localStorage.setItem(this.$key.USER_MAGAGER_CURRPAGE, this.currPage);

      //每页的条数
      localStorage.setItem(this.$key.USER_MAGAGER_CURR_PAGE_SIZE, this.pageSize);

      //搜索关键字
      localStorage.setItem(this.$key.USER_MAGAGER_KEY_VALUE, this.accountValue);
    },

    //同步页面的值
    sysPageParam() {
      //当前页面
      let currPage = localStorage.getItem(this.$key.USER_MAGAGER_CURRPAGE);
      if (!this.isEmpty(currPage)) {
        this.currPage = parseInt(currPage);
      }

      //每页的条数
      let pageSize = localStorage.getItem(this.$key.USER_MAGAGER_CURR_PAGE_SIZE);
      if (!this.isEmpty(pageSize)) {
        this.pageSize = parseInt(pageSize);
      }

      //搜索关键字
      let accountValue = localStorage.getItem(this.$key.USER_MAGAGER_KEY_VALUE);
      if (!this.isEmpty(accountValue)) {
        this.accountValue = accountValue;
      }
    },

    //判断是否显示更改密码
    isShowUpdataPwd(item) {
      let res = false;
      if (this.role == 5) {
        //当前登录的角色是超管
        if (item.role == "超级管理员") {
          //如果当前条目也是超管
          if (item.id == this.roleId) {
            //是自己的账号,显示修改
            res = true;
          } else {
            //不是自己的账号,不显示修改
            res = false;
          }
        } else {
          //当前条目不是超管
          res = true;
        }
      }

      return res;
    },
  },

  computed: {},
  components: { ChangeUserState },

  watch: {
    //使用防抖函数
    accountValue: debounce(function (params) {
      this.currPage = 1;
      this.httpData();
    }, 500),
  },
};
</script>
<style lang="scss" scoped>
.user_manager {
  height: calc(100vh - 87px);
  margin: 0 14px;
  min-width: $tab_min_width;
  .user_manager_content {
    display: flex;
    flex-direction: column;

    .top_action {
      display: flex;
      align-items: center;

      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 210px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }
    }

    .has_padding {
      padding: 0 10px;
    }

    //表格内容部分
    .dv_table_view_content {
      flex: 1;
      margin-top: 14px;
    }
  }
}
</style>
