//禁用和启用用户的弹框
<template>
    <div class="change_user_state_dialog">
        <CommonDialog @close="close" :showDialog="showDialog" :title="title" :width="width">
            <template v-slot:content>
                <div class="del_item_dialog_content">
                    <!-- 提示的内容部分 -->
                    <span class="msg_info">
                        {{message}}
                    </span>

                    <!-- 底部的取消和确定按钮 -->
                    <div class="bom_action">
                        <!-- 取消 -->
                        <div class="cancel" @click.stop="close">
                            <span>取</span><span style="margin-left: 16px">消</span>
                        </div>
                        <!-- 确定 -->
                        <el-button :loading="showCommitLoading" :class="['commit']" @click.stop="doCommitAction">
                            <span>确</span><span style="margin-left: 16px">定</span>
                        </el-button>
                    </div>
                </div>
            </template>
        </CommonDialog>
    </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";

export default {
    data() {
        return {
            //宽度
            width: this.dialogWidth,

            //是否显示确定按钮加载框的标识
            showCommitLoading: false,

            //标题
            title: "",

            //提示文字
            message: "",
        };
    },
    created() {},
    mounted() {
        this.initData();
    },
    methods: {
        //初始化
        initData() {
            if (this.item.state == 0) {
                this.title = "禁用用户";
                this.message = "确定禁用该用户吗？";
            } else if (this.item.state == -2) {
                this.title = "启用用户";
                this.message = "确定启用该用户吗？";
            }
        },

        //关闭弹框
        close() {
            //触发父组件的关闭方法
            this.$emit("close");
        },

        //确定的操作
        doCommitAction() {
            this.showCommitLoading = true;

            //请求服务器做禁用和启用的请求
            let param = this.getHttpParams();
            param.type = 4;
            param.id = this.item.id;
            if (this.item.state == 0) {
                //禁用
                param.disable = -1;
            } else if (this.item.state == -2) {
                //启用
                param.disable = 0;
            }
            this.$http
                .fetchPost(this.$api.ADMINMANAGE, param)
                .then((res) => {
                    let result = res.data;
                    this.showCommitLoading = false;
                    if (result.state == 200) {
                        //请求成功
                        this.showSuccessMsg("操作成功");
                        //同步状态
                        this.item.state == 0
                            ? (this.item.state = -2)
                            : (this.item.state = 0);
                        this.close();
                    } else {
                        this.showErrorMsg(`${result.msg},操作失败`);
                    }
                })
                .catch((err) => {
                    this.showLoading = false;
                    console.log("请求数据异常");
                });
        },
    },
    computed: {},
    components: {
        CommonDialog,
    },
    props: ["showDialog", "item"],
};
</script>
<style lang="scss" scoped>
.change_user_state_dialog {
    .real_content {
        margin: 0 auto;
    }
    ::v-deep .el-dialog {
        top: 50%;
    }

    ::v-deep .el-icon-close:before {
        cursor: pointer;
    }
    .del_item_dialog_content {
        padding: 0 30px;
        display: flex;
        justify-content: center;
        color: black;
        font-size: 14px;
        flex-direction: column;

        .msg_info {
            margin: 40px 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        //底部的操作按钮
        .bom_action {
            height: 48px;
            display: flex;
            justify-content: center;

            .cancel,
            .commit {
                width: 120px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                cursor: pointer;
            }

            .cancel {
                background: #dcdcdc;
                margin-right: 30px;
                color: black;
            }

            .commit {
                background: #409eff;
                color: white;
            }
        }
    }
}
</style>